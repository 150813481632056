.body {
    margin: auto;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
}

.mint-title {
    margin-top: 0px;
}

.nootimg {
    max-width: 200px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mint {
    margin: 10px;
    font-size: 1.8rem;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
}

.minmax {
    background: white;
    cursor: pointer;
    border: solid black 1px;
    font-size: 1.2rem;
}

.sold {
    font-size: 2rem;
    font-weight: 600;
}

.mint-function {
    margin: 10px;
}

.num {
    width: 50px;
    margin: 0px 10px;
    text-align: center;
    border-radius: 4px;
    padding: 2px;
    border: solid black 1px;
    padding: 4px;
}

.mint-description {
    font-weight: 400;
}